import './App.css';
import React, { useState, useEffect } from 'react';
import ResultCard from './ResultCard';
import { Button, Modal } from 'antd';
import { StarTwoTone, FilterTwoTone } from '@ant-design/icons';
import { Space } from 'antd';

import { Checkbox } from 'antd';


function App() {

  // get data from web socket
  const [data, setData] = useState([]);

  // search bar
  const [query, setQuery] = useState('');

  // time to find results
  const [time, setTime] = useState(null);

  const [websckt, setWebsckt] = useState(null);

  const [starredCourses, setStarredCourses] = useState([]);

  const [isStarredModalOpen, setIsStarredModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [checkedList, setCheckedList] = useState([]);

  const showModal = () => {
    setIsStarredModalOpen(true);
  };

  const handleOk = () => {
    setIsStarredModalOpen(false);
  };

  const handleCancel = () => {
    setIsStarredModalOpen(false);
  };

  const showFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleFilterOk = () => {
    setIsFilterModalOpen(false);
  };

  const handleFilterCancel = () => {
    setIsFilterModalOpen(false);
  };

  const onChangeWays1 = (checkedValues) => {
    // add new filters to checked list and remove unchecked filters
    for (let i = 0; i < waysOptions1.length; i++) {
      if (checkedValues.includes(waysOptions1[i].value)) {
        if (!checkedList.includes(waysOptions1[i].value)) {
          checkedList.push(waysOptions1[i].value);
        }
      } else {
        if (checkedList.includes(waysOptions1[i].value)) {
          checkedList.splice(checkedList.indexOf(waysOptions1[i].value), 1);
        }
      }
    }

    // send query to web socket
    if (query !== '' && websckt && websckt.readyState === 1) {
      sendQuery(query, websckt);
    }

  };

  const onChangeWays2 = (checkedValues) => {
    // add new filters to checked list and remove unchecked filters
    for (let i = 0; i < waysOptions2.length; i++) {
      if (checkedValues.includes(waysOptions2[i].value)) {
        if (!checkedList.includes(waysOptions2[i].value)) {
          checkedList.push(waysOptions2[i].value);
        }
      } else {
        if (checkedList.includes(waysOptions2[i].value)) {
          checkedList.splice(checkedList.indexOf(waysOptions2[i].value), 1);
        }
      }
    }

    // send query to web socket
    if (query !== '' && websckt && websckt.readyState === 1) {
      sendQuery(query, websckt);
    }

  };

  const onChangeTerm = (checkedValues) => {
    // add new filters to checked list and remove unchecked filters
    for (let i = 0; i < quarterOptions.length; i++) {
      if (checkedValues.includes(quarterOptions[i].value)) {
        if (!checkedList.includes(quarterOptions[i].value)) {
          checkedList.push(quarterOptions[i].value);
        }
      } else {
        if (checkedList.includes(quarterOptions[i].value)) {
          checkedList.splice(checkedList.indexOf(quarterOptions[i].value), 1);
        }
      }
    }

    // send query to web socket
    if (query !== '' && websckt && websckt.readyState === 1) {
      sendQuery(query, websckt);
    }

  };

  const onChangeUnits = (checkedValues) => {
    // add new filters to checked list and remove unchecked filters
    for (let i = 0; i < unitOptions.length; i++) {
      if (checkedValues.includes(unitOptions[i].value)) {
        if (!checkedList.includes(unitOptions[i].value)) {
          checkedList.push(unitOptions[i].value);
        }
      } else {
        if (checkedList.includes(unitOptions[i].value)) {
          checkedList.splice(checkedList.indexOf(unitOptions[i].value), 1);
        }
      }
    }

    // send query to web socket
    if (query !== '' && websckt && websckt.readyState === 1) {
      sendQuery(query, websckt);
    }

  };

  const quarterOptions = [
    {
      label: 'Aut 🍂',
      value: 'aut',
    },
    {
      label: 'Win 🌨',
      value: 'win',
    },
    {
      label: 'Spr 🌱',
      value: 'spr',
    },
    {
      label: 'Sum 🏝',
      value: 'sum',
    }
  ];

  const waysOptions1 = [
    {
      label: 'A-II',
      value: 'way-a-ii',
    },
    {
      label: 'AQR',
      value: 'way-aqr',
    },
    {
      label: 'CE',
      value: 'way-ce',
    },
    {
      label: 'EDP',
      value: 'way-edp',
    },
  ];

  const waysOptions2 = [
    {
      label: 'ER',
      value: 'way-er',
    },
    {
      label: 'FR',
      value: 'way-fr',
    },
    {
      label: 'SI',
      value: 'way-si',
    },
    {
      label: 'SMA',
      value: 'way-sma',
    },
  ];

  const unitOptions = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
    {
      label: '4',
      value: '4',
    },
    {
      label: '5',
      value: '5',
    },
  ];

  // get starred courses from local storage
  useEffect(() => {
    const starred = JSON.parse(localStorage.getItem("starred"));
    if (starred === null) {
      localStorage.setItem("starred", JSON.stringify([]));
    } else {
      setStarredCourses(starred);
    }
  }, []);

  // check if local storage is updated
  window.addEventListener('storage', () => {
    const starred = JSON.parse(localStorage.getItem("starred"));
    if (starred === null) {
      localStorage.setItem("starred", JSON.stringify([]));
    } else {
      setStarredCourses(starred);
    }

  })

  // function to send query to web socket
  const sendQuery = (query, ws) => {

    var filters = [];
    for (let i = 0; i < checkedList.length; i++) {
      if (checkedList[i].includes('aut') || checkedList[i].includes('win') || checkedList[i].includes('spr') || checkedList[i].includes('sum')) {
        filters.push({
          "type": "terms",
          "value": checkedList[i]
        });
      } else if (checkedList[i].includes('way')) {
        filters.push({
          "type": "ugreqs",
          "value": checkedList[i]
        });
      } else {
        filters.push({
          "type": "units",
          "value": checkedList[i]
        });
      }
    }

    // create json object
    const query_obj = {
      "query": query,
      "filters": filters
    }

    console.log(filters);

    // convert to string
    const to_send = JSON.stringify(query_obj);

    ws.send(to_send);
  }

  // send query to web socket
  useEffect(() => {

    if (query === '') {
      setData([]);
      setTime(null);
      return;
    }

    const delayAmount = 0;
    const delayDebounceFn = setTimeout(() => {
      // check if websocket is defined
      if (websckt && websckt.readyState === 1) {
        sendQuery(query, websckt);
        return;
      } else if (websckt && websckt.readyState === 0) {
        return;
      } else {
        const ws = new WebSocket('wss://cartaboost.up.railway.app/search');
        // const ws = new WebSocket('ws://localhost:8000/search');

        ws.onopen = () => {
          console.log('connected');
          sendQuery(query, ws);
        };
        ws.onmessage = (e) => {
          setData(JSON.parse(e.data)["results"]);
          setTime(JSON.parse(e.data)["time"]);
        };
        ws.onclose = () => {
          console.log('disconnected');
        };

        setWebsckt(ws);
      }
    }, delayAmount)

    return () => clearTimeout(delayDebounceFn)

  }, [query]);


  return (
    <div className="App">
      <Modal title="Starred Classes" open={isStarredModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {starredCourses.map((course) => {
          return <ResultCard modal={true} key={course.id} number={course.number} title={course.title} description={""} attributes={course.attributes} />
        })}
      </Modal>
      <Modal title="Filters" open={isFilterModalOpen} onOk={handleFilterOk} onCancel={handleFilterCancel}>
        <h3>Quarter</h3>
        <Checkbox.Group options={quarterOptions} onChange={onChangeTerm} />
        <h3>Ways Requirements</h3>
        <Checkbox.Group options={waysOptions1} onChange={onChangeWays1} />
        <Checkbox.Group options={waysOptions2} onChange={onChangeWays2} />
        <h3>Units</h3>
        <Checkbox.Group options={unitOptions} onChange={onChangeUnits} />

        <p>Displayed classes will satisfy at least 1 of the filters you select.</p>
        <p style={{ "fontSize": "10px" }}>An experiment by <a href="https://varunshenoy.com/">Varun</a></p>
      </Modal>
      <div>
        <div className='header__div'>
          <p className='header__title'>ten weeks 🚀</p>
          <p className='header__subtitle'>a better course catalog search engine for Stanford students</p>
          <input className='searchBar' placeholder="Search..." onChange={event => setQuery(event.target.value)} />
          <div className="button__div">
            <Button className="header__button" onClick={showModal}>
              <Space>
                <StarTwoTone twoToneColor="#f39c12" />
              </Space>
              &nbsp;
              starred ({starredCourses.length})
            </Button>
            <Button className="header__button" onClick={showFilterModal}>
              <Space>
                <FilterTwoTone twoToneColor="#2980b9" />
              </Space>
              &nbsp;
              filters
            </Button>
          </div>
          {time && <p className='header__time'>found results in {time}ms...</p>}
          {time === null && <p className='header__time'><em>Some ideas: <br></br>"How do I build a rocket?" <br></br> "French philosophy"</em></p>}
        </div>
        {
          data.map(item => {
            return <ResultCard key={item.id} number={item.number} title={item.title} description={item.description} attributes={item.attributes} />
          })
        }
      </div>
    </div>
  );
}

export default App;
