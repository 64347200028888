import { Button } from 'antd';
import { StarTwoTone, ProjectTwoTone } from '@ant-design/icons';
import { Space } from 'antd';
import { useEffect, useState } from 'react';

const ResultCard = (props) => {

    // check if course is starred 
    const [starred, setStarred] = useState(false);

    const starCard = () => {
        const data = {
            "number": props.number,
            "title": props.title,
            "description": props.description,
            "attributes": props.attributes
        }

        // get starred courses from local storage
        const starred = JSON.parse(localStorage.getItem("starred"));

        // if starred is null, create new array
        if (starred === null) {
            localStorage.setItem("starred", JSON.stringify([data]));
        }
        // if starred is not null, add to array
        else {
            starred.push(data);
            localStorage.setItem("starred", JSON.stringify(starred));
        }

        setStarred(true);
        window.dispatchEvent(new Event("storage"));
    }

    const unstarCard = () => {
        // get starred courses from local storage
        const starred = JSON.parse(localStorage.getItem("starred"));

        // if starred is null, create new array
        if (starred === null) {
            localStorage.setItem("starred", JSON.stringify([]));
        }

        // if starred is not null, add to array
        else {
            const index = starred.findIndex((course) => course.number === props.number);
            starred.splice(index, 1);
            localStorage.setItem("starred", JSON.stringify(starred));
            console.log(starred);
        }
        window.dispatchEvent(new Event("storage"));
        if (!props.modal) {
            setStarred(false);
        }
    }

    window.addEventListener('storage', () => {
        const starred = JSON.parse(localStorage.getItem("starred"));

        // check in starred array if course is starred
        if (starred === null) {
            setStarred(false);
        }
        else {
            const index = starred.findIndex((course) => course.number === props.number);
            if (index === -1) {
                setStarred(false);
            } else {
                setStarred(true);
            }
        }
    })


    useEffect(() => {
        // get starred courses from local storage
        const starred = JSON.parse(localStorage.getItem("starred"));

        // if starred is null, create new array
        if (starred === null) {
            setStarred(false);
        }
        // if starred is not null, add to array
        else {
            const index = starred.findIndex((course) => course.number === props.number);
            if (index === -1) {
                setStarred(false);
            } else {
                setStarred(true);
            }
        }
    }, []);



    return (
        <div className="resultCard">
            <div className="resultCard__header">
                <p className="resultCard__header_text">{props.number}: {props.title}</p>
            </div>
            <p className="resultCard__description">{props.description}</p>
            <div className="resultCard__pillContainer">
                {
                    props.attributes.map((attribute, id) => {
                        return (
                            <div key={id} className="resultCard__pill">
                                {attribute}
                            </div>
                        )
                    }
                    )
                }
            </div>
            <div className="resultCard__buttonContainer">
                <a href={"https://carta-beta.stanford.edu/course/" + props.number + "/1234"} target="_blank">
                    <Button type="dashed" className="resultCard__button">
                        view on carta
                        &nbsp;
                        <Space>
                            <ProjectTwoTone twoToneColor="#c0392b" />
                        </Space>
                    </Button>
                </a>
                {!starred && <Button type="dashed" className="resultCard__button" onClick={starCard}>
                    star
                    &nbsp;
                    <Space>
                        <StarTwoTone twoToneColor="#f39c12" />
                    </Space>
                </Button>}
                {starred && <Button type="dashed" className="resultCard__button" onClick={unstarCard}>
                    unstar
                    &nbsp;
                    <Space>
                        <StarTwoTone twoToneColor="#f39c12" />
                    </Space>
                </Button>}
            </div>
        </div >
    );
}

export default ResultCard;